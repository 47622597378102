import React, { Component } from 'react';
import { Segment, Container, Image } from 'semantic-ui-react'
import { PageHeader, PageFooter } from '../components/layout.js'
import { ProductGallery } from '../components/products.js'
import { ProductService } from '../Service.js'
import { SiteContext } from '../contexts/SiteContext.js'
import Slider from 'infinite-react-carousel';
import { SeoHeader} from '../components/seo.js';

import '../home.css';

class Home extends Component {
	constructor(props) {
		super(props)
		this.state = { products: null, loading: true }
    this.productService = new ProductService()
	}
	componentDidMount() {
		this.productService.hot()
			.then(products => {
				this.setState({products: products, loading: false})
			})
	}
  navigateToProduct(product_id) {
		this.props.history.push('/product/'+product_id.toString())
	}

	render () {
		const homeStoryContext = require.context("../images/home/story", true);
		let imgs = {};
		homeStoryContext.keys().forEach(function (key) {
			imgs[key] = homeStoryContext(key);
		});

		return <Container>
			<SeoHeader seo={this.context.seo.home} />
			<PageHeader />
			<Container vertical style={{ padding:'1.5em 0em 0em 0em' }}>
				<Slider autoplay={true} autoplaySpeed={5000} pauseOnHover={true} arrows={false} centerMode={false} >
					{Object.keys(imgs).map((key) => { return <Image src={imgs[key]} key={key} /> })}
				</Slider>
 			</Container>
			<Segment vertical>
				<ProductGallery displayMeta={false} items={this.state.products} onClick={(product_id) => this.navigateToProduct(product_id)} />
			</Segment>
			<PageFooter />
		</Container>
	}
}
Home.contextType = SiteContext
export default Home
