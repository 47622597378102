import React, { Component } from 'react'
import { Container, Button, Segment } from 'semantic-ui-react'
import { PageHeader, PageFooter } from '../components/layout.js';
import { Link } from 'react-router-dom'

class NotFound extends Component {
  render () {
    return <Container>
			<PageHeader />
  		<Segment vertical text textAlign='center'>
				<h1>Oops - Sorry we cannot find that !</h1>
				<Button as={Link} to='/'>Back to home</Button>
			</Segment>
			<PageFooter />
    </Container>
  }
}

export default NotFound
