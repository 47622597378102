import React from 'react';

export const siteConfig = {
	title: 'Vintage Clay Studio',
	tagLine: 'Clay polaroids, tiles and frames',
	contactEmail: 'info@vintageclaystudio.com',
	address: '60 Croham Manor Road, South Croydon, Surrey, CR2 7BF, United Kingdom',
	businessEntity: 'Vintage Clay Studio',
	header: { menuItems: [
						  { name: 'Home', uri: '/', icon: 'home' },
						   ] },
	footer: { sections: [
							{ name: 'About',
								menuItems: [
									{ name: 'Blog', uri: '/blog', icon: 'book' },
									{ name: 'Info', uri: '/about', icon: 'info circle' },
									{ name: 'Contact', uri: '/contact', icon: 'phone' },
									{ name: 'Privacy Policy', uri: '/privacy', icon: 'privacy' },
									{ name: 'Terms & Conditions', uri: '/tcs', icon: 'law' },
									{ name: 'FAQ', uri: '/faq', icon: 'question' },
								] }, {
								name: 'Products',
								menuItems: [
									{ name: 'Clay Polaroids and tiles', uri: '/products/1' },
									{ name: 'Frames', uri: '/products/2' },
									{ name: 'Special Occasions', uri: '/products/3' },
									{ name: 'Clay Photo Gifts', uri: '/products/4' }
								] }] },
	seo: {
		home: { description: 'Personalised Polaroids, Tiles and Frames', keywords: '', title: 'Vintage Clay Studio' },
		about: { description: 'Info', keywords: '', title: 'Information about us' },
		contact: { description: 'Contact us', keywords: '', title: 'Contact us' },
		privacy: { description: 'Privacy Policy', keywords: '', title: 'Privacy Policy' },
		faq: { description: 'Fequently Asked Questions', keywords: '', title: 'Frequently Asked Questions' },
		tcs: { description: 'Terms & Conditions', keywords: '', title: 'Terms & Conditions' },
		blog: { description: 'Blog articles', keywords: '', title: 'Blog' },
	}
}

export const SiteContext = React.createContext(siteConfig)
