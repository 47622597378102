import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import 'semantic-ui-css/semantic.css'
import './index.css';
import 'semantic-ui-less/semantic.less'

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister()
/*
serviceWorker.register({
    onUpdate: registration => {
      alert('New version of the app is available  Ready to update?');

      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      window.location.reload();
    }
  });
	*/
