import React from 'react';
import { Header, Grid, Container, Segment, Dropdown } from 'semantic-ui-react'
import Money from './money.js';

export class Shipping extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(itemId) {
		this.props.onChange(itemId)
	}
	render() {
		let item = this.props.item
		return item
			? <Segment.Group>
			<Segment>
			<Grid columns='16'>
			<Grid.Column width={12} verticalAlign='middle'>
			{item.name}
		{item.signature_required && <strong>
				<br/><i>*requires signature</i>
				</strong>}
			</Grid.Column>
			<Grid.Column width={4} verticalAlign='top' textAlign='right'>
			<Header><Money item={item.price} /></Header>
			</Grid.Column>
			</Grid>
			</Segment>
			</Segment.Group>
			: <Container textAlign='center'>No shipping available - please contact us for a shipping estimate.</Container>
	}
}

export class ShippingCountry extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(_e, { value }) {
		this.props.onChange(value)
	}

	render() {
		const 	countries = [
			{
				key: 'ENG',
				text: 'England',
				value: 'England',
			},
			{
				key: 'WAL',
				text: 'Wales',
				value: 'Wales',
			},
			{
				key: 'SCO',
				text: 'Scotland',
				value: 'Scotland',
			},
			{
				key: 'NI',
				text: 'Northern Ireland',
				value: 'Northern Ireland',
			},
			{
				key: 'OTHER',
				text: 'Other',
				value: 'Other'
			}
		]
		return <Dropdown
		zIndex={99999999999}
		placeholder='Select postage Country'
		fluid
		button
		options={countries} simple item onChange={this.handleChange} />
	}
}
