import React from 'react';
import { Grid, Segment, Card, Input } from 'semantic-ui-react'
import ImageUploadWithResize from './image_upload_with_resize.js'

class BasketProduct extends React.Component {
	constructor(props) {
		super(props);
		this.state = { id: props.id || 1,
									data: { product_id: null,
													images: {},
													messages: {} } }
	}

	render() {
		let product = this.props.product
		let children = product.children
		if (children && children.length > 0) {
			return this.renderChildren(children,
				product.children_row_count, product.options)
		} else {
			 return this.renderProduct(product)
		}
	}
	renderChildren(children, rowSize, _options) {
		const overrideColumnStyle = rowSize > 1 ? {flex: "0 0 " + children[0].options.width * 1.1 + "px"} : {}
		return <Grid centered verticalAlign='middle' padded stackable columns={rowSize}>
			<Grid.Row>
				{
					Object.keys(children).map((key) => {
						return<Grid.Column key={"column-" + key} style={overrideColumnStyle}>
							<BasketProduct
								id={key}
								key={key}
								product={children[key]}
								textChange={this.props.textChange}
								imageSelect={this.props.imageSelect} />
						</Grid.Column>
					})
				}
			</Grid.Row>
	    </Grid>

	}
	renderProduct(product) {
		let options = product.options
		let product_key = this.state.id
		return <Card width={options.width + 'px'}
			           height={options.height + 'px'}>
			<Segment textAlign='center'> {
				Object.keys(options.images).map((key) => {
					return <ImageUploadWithResize
						key={key}
						id={'item_' + product_key + '_' + key}
						onChange={this.props.imageSelect}
						width={options.images[key].width}
						height={options.images[key].height}/>
				})
			} { options.lines_of_text.length > 0  && <Segment > {
				Object.keys(options.lines_of_text).map((key) => {
					return  <Input fluid
						key={key}
						width={options.lines_of_text[key]}
						id={'item_' + product_key + '_' + key}
						onChange={this.props.textChange}
						placeholder='Enter Message'
						maxLength={options.lines_of_text[key]} />
				})
			} </Segment> }
	    </Segment>
    </Card>
	}
}
export default BasketProduct
