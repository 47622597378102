import React from "react";
import { Segment, Header, Input, Button } from "semantic-ui-react";
import BasketProduct from "./basket_product.js";

class BasketItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        product_id: null,
        quantity: 1,
        items: {},
      },
      actions: { addItemToBasket: false },
    };
    this.handleAdd = this.handleAdd.bind(this);
    this.handleImageSelect = this.handleImageSelect.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }
  findOrNew(id) {
    if (this.state.data.items[id] === undefined)
      return { message: null, image: null };
    else return this.state.data.items[id];
  }
  updateItem(id, item) {
    let data = this.state.data;
    data.items[id] = item;
    this.setState({ data: data });
  }
  handleImageSelect(id, file) {
    let item = this.findOrNew(id);
    item.image = file;
    this.updateItem(id, item);
  }
  handleTextChange(event) {
    let item = this.findOrNew(event.target.id);
    item.message = event.target.value;
    this.updateItem(event.target.id, item);
  }
  handleAdd() {
    let data = this.state.data;
    data.product_id = this.props.product.id;
    this.setState({ data: data });
    this.props.addClick(data);
  }
  render() {
    return (
      <Segment.Group>
        <Segment>
          <Header as="h3">Build your {this.props.product.name}</Header>
        </Segment>
        <Segment>
          <div className="ui centered cards">
            <BasketProduct
              product={this.props.product}
              textChange={this.handleTextChange}
              imageSelect={this.handleImageSelect}
            />
          </div>
        </Segment>
        <Segment>
          <label>Quantity</label>
          <Input value={this.state.data.quantity} />
        </Segment>
        <Segment textAlign="right">
          <Button
            disabled={!this.state.actions.addItemToBasket}
            primary
            onClick={() => this.handleAdd()}
          >
            Add to Basket
          </Button>
        </Segment>
      </Segment.Group>
    );
  }
}
export default BasketItem;
