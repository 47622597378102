import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export class SeoHeader extends Component {
	render() {
		const seo = this.props.seo
		return <Helmet>
			<title>{ seo.title || seo.name }</title>
			<meta name="description" content={ seo.description } />
			<meta name="keywords" content={ seo.keywords } />
		</Helmet>
	}
}
