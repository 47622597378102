import React from 'react';
import { Header, Grid, Container, Segment, Card, Dimmer, Loader, Dropdown, Checkbox, Modal, Label, Message, Button } from 'semantic-ui-react'
import { PageHeader, PageFooter } from '../components/layout.js';
import PaypalButton from '../components/paypal_button.js';
import { Shipping, ShippingCountry } from '../components/shipping.js';
import OrderItemsTable from '../components/order_items_table.js';
import Money from '../components/money.js';
import { TermsAndConditionsText } from '../components/terms_conditions_text.js';
import { BasketService, PaymentService } from '../Service.js';
import { ProcessingInfo, DeliveryInfo, CancellationInfo } from '../components/help.js';
import { DiscountOffer } from '../components/discount_offer';

import ReactGA from 'react-ga';

class Checkout extends React.Component {
	constructor() {
		super()

		this.state = { basket: {
										order_items:[],
										tax: 0.00,
										shipping: 0.00,
										subtotal: 0.00 },
									 shipping_country: null,
									 payment_order: {},
									 loading: true,
									 image_permission: null,
			             accept_terms_and_conditions: false,
									 result: {},
									 offer: { error: null } }

    this.basketService = new BasketService()
    this.paymentService = new PaymentService()

		this.getBasket = this.getBasket.bind(this)
		this.handleChange = this.handleChange.bind(this)
    this.handlePaymentSuccess = this.handlePaymentSuccess.bind(this)
	}
	componentDidMount () {
		this.startLoading()
		this.getBasket()
	}
	startLoading() {
		this.setState({loading: true})
	}
	async getBasket() {
		this.startLoading()
		let basket = await this.basketService.all()
		let payment_order =  await this.paymentService.new()
		this.setState({basket: basket, payment_order: payment_order, loading: false})
	}
	async handleChange(item, count) {
		this.startLoading()

		let basket = {}

		if ( count > 0 ) {
			basket  = await this.basketService.update(item.id, count)
		} else {
			basket = await this.basketService.remove(item.id)
		}

		let payment_order =  await this.paymentService.new()

		this.setState({basket: basket, payment_order: payment_order, loading: false})
	}

	async handlePaymentSuccess(data) {
		this.startLoading()
		let payment_details = await this.paymentService.create(this.state.basket.id, data.id, this.state.image_permission)
		this.setState({basket: {}, result: payment_details, loading: false})
		this.registerPurchase()
	}

	handleApplyDiscountOffer = (code) => {
		this.startLoading()

		if (!code || code === '') {
			this.basketService.removeOffer(code).then((offerResponse) => {
				this.paymentService.new().then((payment_order) => {
					this.setState(
						{ ...this.state,
							basket: offerResponse.data,
							offer: { error: offerResponse?.issue?.message },
							payment_order:
							payment_order,
							loading: false}
					)
				})
			})
		} else {
			this.basketService.addOffer(code).then((offerResponse) => {
				this.paymentService.new().then((payment_order) => {
					this.setState(
						{ ...this.state,
							basket: offerResponse.data,
							offer: { error: offerResponse?.issue?.message},
							payment_order:
							payment_order,
							loading: false}
					)
				})
			})
		}
	}

	handleContinueShopping = (_e) =>
	  { this.props.history.push('/'); }

	handlePermissionChange = (_e, { value }) =>
		this.setState({ image_permission: value })

	handleTermsAndConditionsChange = (_e, { checked }) =>
		this.setState({ accept_terms_and_conditions: checked })

	handleShippingCountryChange = (value) =>
		this.setState((value && value.length && {
			shipping_country_valid: value.toUpperCase() !== 'OTHER',
			shipping_country: value
		})  || {} )

	registerPurchase() {
		try {
			ReactGA.set({ page: "/payment-success" }); // Update the user's current page
			ReactGA.pageview("/payment-success") // Record a successful checkout
		} catch(e) { console.log(e); }
	}

	render() {
		let hasOrderItems = this.state.basket.order_items && this.state.basket.order_items.length !== 0
		let shipping_days = this.state.basket.shipping_lead_time_days || 14
		let shipping_country = this.state.shipping_country
		let shipping_country_valid = this.state.shipping_country_valid
		let can_ship = (shipping_country && shipping_country.length > 0) && shipping_country_valid
		let { tax, subtotal, total, discount, subtotal_no_discount, offer_code } = this.state.basket
		let error = this.state.offer.error
		let payment_order = this.state.payment_order
		let image_permission = this.state.image_permission
		let accept_terms_and_conditions = this.state.accept_terms_and_conditions
		let options = [
			{ key: 1, text: 'Yes of course', value: true },
			{ key: 2, text: 'No thank you', value: false }
		]
		return <Container>
			<Dimmer active={this.state.loading}>
				<Loader content='Updating' size='large' />
			</Dimmer>
			<PageHeader />
      <Segment>
			<Grid>
				<Grid.Row columns={2}>
					<Grid.Column>
						<Header as='h1'>Your Order</Header>
					</Grid.Column>
					<Grid.Column textAlign='right'>
						{ hasOrderItems &&
						   <Button basic color='green' onClick={this.handleContinueShopping}>Continue Shopping</Button>
						}
					</Grid.Column>
				</Grid.Row>
			</Grid>
      </Segment>
			{ this.state.result.status
				?	<PaymentResult item={this.state.result} />
				: !hasOrderItems
					?  <Segment textAlign='center' raised>
							<br/>
							<Header as='h2'>Your basket is empty</Header>
							<br/>
						</Segment>
					: <Container>
						<Segment raised>
							<Grid divided='vertically' stackable>
								<Grid.Row>
									<Grid.Column width={16}>
										<Header as='h3'>Items</Header>
										<OrderItemsTable
											items={this.state.basket.order_items}
											onChange={(item, count) =>
													this.handleChange(item, count) } />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<Header as='h3'>Shipping</Header>
									</Grid.Column>
									<Grid.Column width={8}>
								    <ShippingCountry onChange={ (item, value) => this.handleShippingCountryChange(item, value) } />
										{
											 ( (shipping_country && shipping_country.length > 0) && !shipping_country_valid )
												? <Message className='error' >Shipping to this country is not supported</Message>
											  : (shipping_country && shipping_country.length > 0) && <Shipping item={this.state.basket.shipping_rate} />
										}
										<Message className={shipping_days > 14 ? 'warning' : 'info'}>
											Expected fulfillment time due to current demand is {shipping_days} days
										</Message>
									</Grid.Column>
								</Grid.Row>
							{ can_ship &&	<Grid.Row>
										<Grid.Column width={8}>
											<Header as='h3'>Offer Code</Header>
									</Grid.Column>
										<Grid.Column textAlign='right' width={8}>
										<DiscountOffer
											 code={offer_code}
											 errorMessage={error}
				               onClick={(code) => this.handleApplyDiscountOffer(code)} />
									</Grid.Column>
								</Grid.Row>
							}
							{ can_ship &&
								<Grid.Row>
									<Grid.Column width={16} verticalAlign='top'>
									<Grid>
								{ (discount > 0) &&
									<Grid.Row>
										<Grid.Column width={10} verticalAlign='middle'>
											<Header as='h3'>Sub total (before discount)</Header>
										</Grid.Column>
										<Grid.Column width={6} verticalAlign='middle' textAlign='right'>
											<Header><Money item={subtotal_no_discount} /></Header>
										</Grid.Column>
									</Grid.Row> }
								{ (discount > 0) &&
									<Grid.Row>
										<Grid.Column width={10} verticalAlign='middle'>
											<Header as='h3'>Discount</Header>
										</Grid.Column>
										<Grid.Column width={6} verticalAlign='middle' textAlign='right'>
											<Header><Money item={discount} /></Header>
										</Grid.Column>
									</Grid.Row>
								}
									<Grid.Row>
										<Grid.Column width={10} verticalAlign='middle'>
											<Header as='h3'>Sub Total</Header>
										</Grid.Column>
										<Grid.Column width={6} verticalAlign='middle' textAlign='right'>
											<Header><Money item={subtotal} /></Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={10} verticalAlign='middle'>
											<Header as='h3'>Tax</Header>
										</Grid.Column>
										<Grid.Column width={6} verticalAlign='middle' textAlign='right'>
											<Header><Money item={tax} /></Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={10} verticalAlign='middle'>
											<Header as='h3'>
												Total&nbsp;&nbsp;
												<i>(incl Tax &amp; Shipping)</i>
											</Header>
										</Grid.Column>
										<Grid.Column width={6} verticalAlign='middle' textAlign='right'>
											<Header><Money item={total} /></Header>
										</Grid.Column>
									</Grid.Row>
									</Grid>
									</Grid.Column>
								</Grid.Row>
								}
								{ can_ship &&
								<Grid.Row>
									<Grid.Column width={16} verticalAlign='middle' textAlign='right'>
										<Label>Can I share pictures of your final product ?</Label>&nbsp;
										<Dropdown
											button
											basic
											placeholder='Please select Yes or No'
											options={options} simple item onChange={this.handlePermissionChange} />
									</Grid.Column>
								</Grid.Row>
								}
							  { can_ship &&
								<Grid.Row>
									<Grid.Column width={16} verticalAlign='middle' textAlign='right'>
										<Label as='a' horizontal >Check the box to accept the <TermsAndConditionsModal/> </Label>
										<Checkbox onChange={this.handleTermsAndConditionsChange}/>
									</Grid.Column>
								</Grid.Row>
								}
								{ can_ship &&
								<Grid.Row>
									<Grid.Column width={2} verticalAlign='middle'></Grid.Column>
									<Grid.Column width={6}></Grid.Column>
									<Grid.Column width={7} verticalAlign='middle' textAlign='right'>
										{ (total && total > 0) && can_ship && accept_terms_and_conditions && image_permission !== null && <PaypalButton
											total={total}
											paymentOptions={payment_order}
											onSuccess={(p) => this.handlePaymentSuccess(p)} /> }
									</Grid.Column>
									<Grid.Column width={1}></Grid.Column>
								</Grid.Row>
							}
							</Grid>
						</Segment>
					  { can_ship &&
						<Message info>
							Expected fulfillment time due to current demand is {shipping_days} days
						</Message>
						}
						<ProcessingInfo />
						<DeliveryInfo />
						<CancellationInfo />
						<br />
					</Container>
			}
			<PageFooter />
		</Container>
	}
}

const TermsAndConditionsModal = () => (
	<Modal trigger={<i color='blue'>terms and conditions</i>}
		header='Terms and Conditions'
		content={<Modal.Description><TermsAndConditionsText /></Modal.Description>}
		actions={[{ key: 'done', content: 'OK', positive: true }]}
	/>
)

class PaymentResult extends React.Component {
	render() {
		let paymentResult = this.props.item
		return paymentResult && paymentResult.status === 'paid'
			? <Card fluid>
				  <Card.Header textAlign='center' as='h1'>
						Thank you for your payment
					</Card.Header>
					<Card.Content>
						<Card.Meta>
							<strong>Order Ref:</strong>&nbsp;
							VINTAGE_CLAY_{paymentResult.order_id}
						</Card.Meta>
						<Card.Meta>
							<strong>Charged on:</strong>&nbsp;
							{paymentResult.charged_at}
						</Card.Meta>
						<Card.Meta>
							<strong>Provider Transaction ID:</strong>&nbsp;
							{paymentResult.transaction_id}
						</Card.Meta>
						<Card.Description>
							Your will receive a confirmation email shortly with these details but please make a note of your <strong>Order Ref</strong>.
						</Card.Description>
					</Card.Content>
					<Card.Content extra>
						<Header as='h4'>
							Your order is in our queue and will be completed within 14 days as per our terms and conditions.
						</Header>
					</Card.Content>
				</Card>
			: <Card fluid>
					<Card.Header as='h1'>
					  We are sorry but your payment was not accepted
					</Card.Header>
					<Card.Content textAlign='center'>
						<Card.Meta></Card.Meta>
						<Card.Description>
							Please try again, alternatively contact us by email.
						</Card.Description>
					</Card.Content>
					<Card.Content extra>
					</Card.Content>
				</Card>
	}
}

export default Checkout
