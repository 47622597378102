import React, { Component } from 'react';
import { Segment, Container, Dimmer, Loader } from 'semantic-ui-react'
import { PageHeader, PageFooter } from '../components/layout.js';
import { ArticleList } from '../components/articles';
import { Helmet } from 'react-helmet';
import { BlogService } from '../Service'


export default class Blog extends Component {
	constructor(props) {
		super(props)
		this.state = { articles: null, loading: true }
    this.blogService = new BlogService()
	}

	componentDidMount() {
		this.blogService.all()
			.then(articles => {
				this.setState({articles: articles, loading: false})
			})
	}

 navigateTo(article_id) {
		this.props.history.push('/blog/' + article_id.toString())
	}
 	render () {
		const {articles} = this.state
		return <Container>
			<Helmet>
				<title>{ '' }</title>
				<meta name="description" content={ '' } />
			</Helmet>

			<PageHeader title={this.props.match.params.title} />
  		<Segment vertical>
			</Segment>
  		<Segment vertical>
				<Dimmer active={this.state.loading} inverted>
					<Loader content='Loading' size='large' />
				</Dimmer>
				<ArticleList
					items={articles}
					onClick={(article_id) => this.navigateTo(article_id)} />
			</Segment>
			<PageFooter />
		</Container>
	}
}

