import React, { Component } from 'react';
import { Segment, Container, Dimmer, Loader } from 'semantic-ui-react'
import { PageHeader, PageFooter } from '../components/layout.js';
import { ProductList } from '../components/products';
import { ProductService, CategoryService } from '../Service.js';
import { Helmet } from 'react-helmet';

class Products extends Component {
	constructor(props) {
		super(props)
		this.state = { loading: true, category_id: null }

    this.productService = new ProductService()
    this.categoryService = new CategoryService()
	}
	componentDidMount () {
    const { match: { params } } = this.props;
	  this.getProductsByCategory(params.categoryId)
	}
	componentDidUpdate(prevProps) {
    if(this.props.match.params.categoryId !== prevProps.match.params.categoryId) {
			this.componentDidMount()
   }
  }
	startLoading() {
		this.setState({loading: true})
	}
	getProductsByCategory(id) {
		this.setState({products: null, loading: true})
		this.productService.forCategory(id)
			  .then(products => this.setState({products: products, loading: false}) )
	}
  navigateToProduct(product_id) {
		this.props.history.push('/product/'+product_id.toString())
	}
	render () {
		const {products} = this.state
		return <Container>
			<Helmet>
				<title>{ '' }</title>
				<meta name="description" content={ '' } />
			</Helmet>

			<PageHeader title={this.props.match.params.title} />
  		<Segment vertical>
				<Dimmer active={this.state.loading} inverted>
					<Loader content='Loading' size='large' />
				</Dimmer>
				<ProductList
					items={products}
					onClick={(product_id) => this.navigateToProduct(product_id)} />
			</Segment>
			<PageFooter />
		</Container>
	}
}

export default Products
