import React from 'react';
import { Segment, Container, Header, Card, Image } from 'semantic-ui-react'
import placeholder from '../images/img-placeholder.png'

class ArticleList extends React.Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}
	onClick(item_id) {
		this.props.onClick(item_id);
	}
	render() {
		const items = this.props.items
		const currentItem = this.props.currentItem
		return items && items.length
			? <Card.Group centered >
				{Object.keys(items).map((key) => {
					return <ArticleListItem
						        key={key}
						        isCurrentItem={currentItem && items[key] === currentItem.id}
									  item={items[key]}
										onClick={(item_id) => this.onClick(item_id.slug)} />
				})}
			</Card.Group>
			: <Container textAlign='center'>No items found.</Container>
	}
}

class ArticleListItem extends React.Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}
	onClick(article) {
		this.props.onClick(article);
	}
	render() {
		const item = this.props.item
		return <Card raised onClick={() => this.onClick(item)}>
			{ item.image_url ?
						<Card.Content alt={item.name} style={{
							height:200,
							overflow:'hidden',
							margin:10,
							backgroundSize: 'cover',
							backgroundPosition: 'center center',
							backgroundImage: 'url(\'' +  item.image_url + '\')' }} >
						</Card.Content>
				: <Card.Content alt={item.name}><Image src={placeholder} /></Card.Content> }

						<Card.Content extra style={{height:180, overflow:'hidden'}}>
							<Header as='h4'>{item.name}</Header>
							<Card.Meta>
								<div>
								{ item.description}
								</div>
							</Card.Meta>
						</Card.Content>
						<Card.Content extra textAlign='right'>
						  <b>Published</b> <i>{item.published_at}</i>
						</Card.Content>
					</Card>
  }
}

class Article extends React.Component {
	render() {
		const {item} = this.props
		return item ?
			<Segment center>
				 <Image key='main_image' src={item.image_url} fluid />
			   <h2>{ item.name }</h2>
				 <p><div dangerouslySetInnerHTML={{ __html: item.body_html}} /></p>
			</Segment>
			: ""
	}
}
export { ArticleList, ArticleListItem, Article }
