import React, { Component } from 'react';
import { Segment, Container, Header, Grid, Image, Card } from 'semantic-ui-react'
import { PageHeader, PageFooter } from '../components/layout.js';
import founder from '../images/about/founder.jpg';
import studio from '../images/about/studio.jpg';
import handtiles from '../images/about/hand-tiles-bw.jpg';
import { siteConfig } from '../contexts/SiteContext';
import { SeoHeader } from '../components/seo'


/* 





*/ 


class About extends Component {
	render () {
		return <Container>
			<SeoHeader seo={siteConfig.seo.about } />
			<PageHeader />
			<Segment>
				<Header as='h1'>
					<Header.Content>
						About Vintage Clay Studio
					</Header.Content>
				</Header>
			</Segment>
			<Segment style={{textAlign: 'justify'}}>
				<Grid stackable>

					<Grid.Row>
						<Grid.Column width={16}>
						<Header as='h3'>
						  A beautiful way to display your treasured memories.
						</Header>
						</Grid.Column>
          </Grid.Row>
					<Grid.Row>
						<Grid.Column width={8}>
							<p>
They say, ‘necessity is the mother of invention’ and that’s certainly how
Vintage Clay Studio came about.  I’ve got a degree in Fine Art, a love of
anything creative and after years of teaching Art & Design, I needed to be home
more for my children.
							</p>

							<p>
The idea of adding personalization to old photos led to the very first clay
polaroid and it’s safe to say I under-estimated how popular it would be. In
2017; able to afford a small garden studio, my little business has gone from
strength to strength.
							</p>
							<Card fluid>
								<Image src={handtiles} alt={'handmade clay gifts'} />
							</Card>

							<Card fluid>
								<Image src={founder} alt={ 'the founder' } />
							</Card>

						</Grid.Column>
						<Grid.Column width={8} verticalAlign={'top'}>
							<Card fluid>
								<Image src={studio} alt={'My Studio'} />
							</Card>
							<p>
The whole space has had a little makeover this summer, so I thought some new
photos were called for. To be completely honest, it’s only this clean and
organized about once a year!
							</p>
				<p>
I can make clay tiles in basically any shape or size which means there’s a huge
variety of products to choose from. The original clay polaroid is still a
favourite but must admit, I love the large, tiled frames.
               </p>
							<p>
Each one is personalized with your photos and messages, making them all
completely unique. I love seeing your photos and hearing the stories behind
them. Thank you for stopping by, I really hope you like what you see.
      </p>
							<p>
								<br/>
							</p>
						  <Header as='h1' style={{ fontFamily: 'bayberry', fontSize: '3em' }}><i>Michelle x</i></Header>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
			<PageFooter />
		</Container>
	}
}

export default About
