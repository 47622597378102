import React from 'react';

export const faqs = {
  questions: [
		{ q: 'How long will my order take ?',
			a: 'The dispatch time for orders is 14 working days, if you’d like something urgently just pop me an email and I’ll see what I can do.' },
		{ q: 'Do I need to send you original photos ?',
			a: 'No, I don’t need originals. Just upload the pictures when you order, either from your phone or computer.' },
		{ q: 'The photos are old, will they work ?',
			a: 'Obviously, the quality of the photo is important for the look of the final piece but old photos can look lovely on clay tiles.  Unfortunately, I can’t edit or repair damaged photos.' },
		{ q: 'Can you change my photos from colour to black & white ?',
			a: 'Yes I can, just add this information to the order.'},
		{ q: 'When will I know to expect my order ?',
			a: 'You will receive a dispatch email when your order is on its way.'},
		{ q: 'Will you share pictures of my order without my permission ?',
			a: 'At checkout you will be asked for permission, if you’d rather not, that’s absolutely fine.'},
		{ q: 'There’s a problem with my order, what can I do ?',
			a: 'Just send an email, I do make mistakes occasionally but always try and put it right.'},
		{ q: 'I have an idea for a product, do you offer a bespoke service ?',
			a: 'Yes I do, I’m always happy to discuss your ideas. Just pop me an email.'}
  ]
}

export const FAQContext = React.createContext(faqs)
