import React from 'react';
import { Header, Grid, Container, Card, Image, Input, Icon, Button, Confirm } from 'semantic-ui-react'
import Money from './money.js';
import placeholder from '../images/img-placeholder.png'

class OrderItemsTable extends React.Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}
	onChange(item, count) {
		this.props.onChange(item, count);
	}
	render() {
		let items = this.props.items
		let currentItem = this.props.currentItem
		return items && items.length
			? <Grid stackable>
				{Object.keys(items).map((key) => {
					return <OrderItemRow
						key={key}
						isCurrentItem={currentItem && items[key] === currentItem.id}
						item={items[key]}
						onChange={(item, count) => this.onChange(item, count)} />
				})}
			</Grid>
			: <Container textAlign='center'>No items found.</Container>
	}
}

class OrderItemRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = { open: false }
		this.onChange = this.onChange.bind(this);
		this.showConfirm = this.showConfirm.bind(this);
		this.handleConfirm = this.handleConfirm.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
	}
	onChange(order_item, count) {
		this.props.onChange(order_item, count);
	}
	showConfirm() {
		this.setState({open: true})
	}
	handleConfirm() {
		this.setState({open: false})
		this.onChange(this.props.item, 0)
	}

	handleCancel() {
		this.setState({open: false})
	}
render() {
	let item = this.props.item
	return <Grid.Row verticalAlign='top'>
			<Grid.Column width={11}>
				<Header>{item.name} - <Money item={item.unit_price} /></Header>
			  <OrderItem order_item={item} />
			</Grid.Column>
			<Grid.Column width={3}>
				<Button primary icon='minus'
					onClick={(_e) => this.onChange(item, (item.quantity - 1))} />
				<Input style={{width: '50px'}} size='small'
					value={item.quantity}
					readOnly />
				<Button primary icon='add'
					onClick={(_e) => this.onChange(item, (item.quantity + 1))} />
				<br/>
				<Icon link name='close' color='red' size='small'
					onClick={this.showConfirm}>&nbsp;remove</Icon>
				<Confirm open={this.state.open}
					content='Are you sure you want to remove this item from your order?'
					onCancel={this.handleCancel}
					onConfirm={this.handleConfirm} />
			</Grid.Column>
			<Grid.Column width={2} textAlign='right'>
				<Header><Money item={item.total_price} /></Header>
			</Grid.Column>
		</Grid.Row>
	}
}

class OrderItem extends React.Component {
	render() {
		const item = this.props.order_item
		return <Grid centered verticalAlign='middle' padded stackable columns={item.children_row_count}>
			<Grid.Row>
				{
					Object.keys(item.image_urls).map((key) => {
						return <Grid.Column key={key}>
							<Card raised key={key}>
								{ item.image_urls[key] && <Card.Content>
									<Image src={item.image_urls[key] || placeholder}
										alt={item.description}
										fluid bordered  />
								</Card.Content> }
								{ item.messages[key] &&
									<Card.Content extra textAlign='center' verticalalign='middle'>
										{
											Object.keys(item.messages[key]).map((mKey) => {
												return <Card.Meta key={mKey}>{ item.messages[key][mKey] }</Card.Meta>
											})
										}
									</Card.Content>
								}
							</Card>
						</Grid.Column>
					})
				}
			</Grid.Row>
		</Grid>
	}
}

export default OrderItemsTable
