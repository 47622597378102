import React from 'react'
import ReactAvatarEditor from 'react-avatar-editor'
import { Grid } from 'semantic-ui-react'
import placeholder from '../images/img-placeholder.png'

class ImageUploadWithResize extends React.Component {
	_mounted = false
	state = {
		image: placeholder,
		allowZoomOut: true,
		position: { x: 0.5, y: 0.5 },
		scale: 1,
		rotate: 0,
		borderRadius: 0,
		preview: null,
		width: this.props.width,
		height: this.props.height,
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	handleNewImage = e => {
		this.setState({ image: e.target.files[0] })
	}

	handleSave = _data => {
		if (this._mounted) {
			const img = this.editor.getImageScaledToCanvas().toDataURL()
			this.props.onChange(this.props.id, img)
		}
	}

	handleScale = e => {
		const scale = parseFloat(e.target.value)
		this.setState({ scale })
	}

	handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
		this.setState({ allowZoomOut })
	}

	rotateLeft = e => {
		e.preventDefault()
		this.setState({ rotate: this.state.rotate - 90, })
	}

	rotateRight = e => {
		e.preventDefault()
		this.setState({ rotate: this.state.rotate + 90, })
	}

	handleBorderRadius = e => {
		const borderRadius = parseInt(e.target.value)
		this.setState({ borderRadius })
	}

	handleXPosition = e => {
		const x = parseFloat(e.target.value)
		this.setState({ position: { ...this.state.position, x } })
	}

	handleYPosition = e => {
		const y = parseFloat(e.target.value)
		this.setState({ position: { ...this.state.position, y } })
	}

	handleWidth = e => {
		const width = parseInt(e.target.value)
		this.setState({ width })
	}

	handleHeight = e => {
		const height = parseInt(e.target.value)
		this.setState({ height })
	}

	handleCallback(_e) {
		this.handleSave()
	}

	setEditorRef = editor => {
		if (editor) this.editor = editor
	}

	handlePositionChange = position => {
		this.setState({ position })
	}

	handleDrop = acceptedFiles => {
		this.setState({ image: acceptedFiles[0] })
	}

	render() {
		let rowStyle = {paddingTop:0, paddingBottom:0}
		return (
			<Grid>
				<Grid.Row style={{paddingBottom:0}}>
					<Grid.Column width={3}>
				    <i className="ui undo icon" onClick={this.rotateRight}></i>
					</Grid.Column>
					<Grid.Column width={10}>
            <strong>
                Image { parseInt(this.props.id.split('_')[1]) + 1 }
            </strong>
            <br/>
						<input
							name="scale"
							type="range"
							onChange={this.handleScale}
							min={0.1}
							max="10"
							step="0.01"
							defaultValue="1"
						/>
					</Grid.Column>
					<Grid.Column width={3}>
            <i title="rotate right" className="ui redo icon" onClick={this.rotateLeft}></i>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row style={rowStyle}>
					<Grid.Column width={16} style={{padding:0, margin:0}}>
						<ReactAvatarEditor
							ref={this.setEditorRef}
							color={[0, 0, 0, 0.8]}
							border={0}
							scale={parseFloat(this.state.scale)}
							width={this.state.width}
							height={this.state.height}
							position={this.state.position}
							onPositionChange={this.handlePositionChange}
							rotate={parseFloat(this.state.rotate)}
							borderRadius={this.state.width / (100 / this.state.borderRadius)}
							onLoadFailure={this.handleCallback.bind(this, 'onLoadFailed')}
							onLoadSuccess={this.handleCallback.bind(this, 'onLoadSuccess')}
							onImageReady={this.handleCallback.bind(this, 'onImageReady')}
							onImageChange={this.handleCallback.bind(this, 'onImageChange')}
							image={this.state.image}
							className="editor-canvas"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row style={{paddingTop:5,paddingBottom:5}}>
					<Grid.Column width={16}>
							<label htmlFor={this.props.id} className="ui icon button">
								<i className="ui upload icon"></i>Pick your Image
							</label>
							<input type="file"
								id={this.props.id}
								style={{display: 'none'}}
								onChange={this.handleNewImage} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		)
	}
}
export default ImageUploadWithResize
