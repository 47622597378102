import React from 'react';
import ReactDOM from 'react-dom';

class PaypalButton extends React.Component {
	constructor(props) {
		super(props);
		window.React = React;
		window.ReactDOM = ReactDOM;
    this.createOrder = this.createOrder.bind(this)
    this.onApprove = this.onApprove.bind(this)
	}
	createOrder(data, actions) {
		return actions.order.create({ purchase_units: [this.props.paymentOptions] });
	}
	onApprove(data, actions) {
		return actions.order.capture()
			.then(details => this.props.onSuccess(details))
	}
	render() {
		const ReactButton = window.paypal.Buttons.driver('react', { React, ReactDOM });
		return <ReactButton
						createOrder={ (data, actions) => this.createOrder(data, actions) }
            onApprove={ (data, actions) => this.onApprove(data, actions) } />
	}
}
export default PaypalButton
