import React, { Component } from 'react';
import { Segment, Header } from 'semantic-ui-react'

class TermsAndConditionsText extends Component {
	render () {
		let owner = 'Michelle Shirley'
		let siteName = 'Vintage Clay Studio'
		let siteUrl = 'www.vintageclaystudio.com'
		let email = 'info@vintageclaystudio.com'
		return <Segment>
				<Header as='h3'>Introduction</Header>
				<p>
					These terms and conditions apply between you, the User of this Website
					(including any sub-domains, unless expressly excluded by their own
					terms and conditions), and { owner } trading as { siteName },
					the owner and operator of this Website. Please read these terms and
					conditions carefully, as they affect your legal rights. Your agreement
					to comply with and be bound by these terms and conditions is deemed
					to occur upon your first use of the Website. If you do not agree to
					be bound by these terms and conditions, you should stop using the
					Website immediately.
				</p>
				<p>
					In these terms and conditions, <b>User</b> or <b>Users</b> means any
					third party that accesses the Website and is not either (i) employed by
					{ owner } trading as { siteName } and acting in the course of their
					employment or (ii) engaged as a consultant or otherwise providing
					services to { owner } trading as { siteName } and accessing the
					Website in connection with the provision of such services.
				</p>
				<p>
					You must be at least 18 years of age to use this Website. By using
					the Website and agreeing to these terms and conditions, you represent
					and warrant that you are at least 18 years of age.
				</p>

				<Header as='h3'>Intellectual property and acceptable use</Header>
				<ol>
					<li>All Content included on the Website, unless uploaded by Users, is
						the property of { owner } trading as { siteName }, our affiliates or
						other relevant third parties. In these terms and conditions, Content
						means any text, graphics, images, audio, video, software, data
						compilations, page layout, underlying code and software and any
						other form of information capable of being stored in a computer
						that appears on or forms part of this Website, including any such
						content uploaded by Users.
						By continuing to use the Website you acknowledge that such Content
						is protected by copyright, trademarks, database rights and other
						intellectual property rights. Nothing on this site shall be
						construed as granting, by implication, estoppel, or otherwise, any
						license or right to use any trademark, logo or service mark
						displayed on the site without the owner's prior written permission
					</li>
					<li>
						You may, for your own personal, non-commercial use only, do the
						following:
						<ol>
							<li>retrieve, display and view the Content on a computer screen</li>
						</ol>

					</li>
					<li>
						You must not otherwise reproduce, modify, copy, distribute or use
						for commercial purposes any Content without the written permission of
						{ owner } trading as { siteName }.
					</li>
					<li>
						You acknowledge that you are responsible for any Content you may
						submit via the Website, including the legality, reliability,
						appropriateness, originality and copyright of any such Content.
						You may not upload to, distribute or otherwise publish through the
						Website any Content that (i) is confidential, proprietary, false,
						fraudulent, libellous, defamatory, obscene, threatening, invasive
						of privacy or publicity rights, infringing on intellectual property
						rights, abusive, illegal or otherwise objectionable;
						(ii) may constitute or encourage a criminal offence, violate the
						rights of any party or otherwise give rise to liability or violate
						any law; or
						(iii) may contain software viruses, political campaigning, chain
						letters, mass mailings, or any form of "spam." You may not use a
						false email address or other identifying information, impersonate
						any person or entity or otherwise mislead as to the origin of any
						content. You may not upload commercial content onto the Website.
					</li>

					<li>
						You represent and warrant that you own or otherwise control all the
						rights to the Content you post; that the Content is accurate; that
						use of the Content you supply does not violate any provision of
						these terms and conditions and will not cause injury to any person;
						and that you will indemnify { owner } trading as { siteName }
						for all claims resulting from Content you supply.
					</li>
				</ol>

				<Header as='h3'>Prohibited use</Header>
				<ol className="clauses">
					<li> You may not use the Website for any of the following purposes:
						<ol>
							<li>in any way which causes, or may cause, damage to the Website or interferes with any other person's use or enjoyment of the Website;
							</li>
							<li>in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise objectionable or in breach of any applicable law,
								regulation, governmental order;
							</li>
							<li>making, transmitting or storing electronic copies of Content protected by copyright without the permission of the owner.
							</li>
						</ol>
					</li>
				</ol>

				<Header as='h3'>Registration</Header>
				<ol className="clauses">
					<li>You must ensure that the details provided by you on registration or at any time are correct and complete.
					</li>
					<li> You must inform us immediately of any changes to the information that you provide when registering by updating your personal details to ensure we can
						communicate with you effectively.
					</li>
					<li>We may suspend or cancel your registration with immediate effect for any reasonable purposes or if you breach these terms and conditions.
					</li>
					<li>You may cancel your registration at any time by informing us in writing to the address at the end of these terms and conditions. If you do so, you must
						immediately stop using the Website. Cancellation or suspension of your registration does not affect any statutory rights.
					</li>
				</ol>

				<Header as='h3'>Links to other websites</Header>
				<ol className="clauses">
					<li>This Website may contain links to other sites. Unless expressly stated, these sites are not under the control of
						{ owner } trading as { siteName }
						or that of our affiliates.
					</li>
					<li>
						We assume no responsibility for the content of such Websites and disclaim liability for any and all forms of loss or damage arising out of the use of them.
					</li>
					<li>
						The inclusion of a link to another site on this Website does not imply any endorsement of the sites themselves or of those in control of them.
					</li>
				</ol>

				<Header as='h3'>Privacy Policy and Cookies Policy</Header>
				<ol className="clauses">
					<li>
						Use of the Website is also governed by our Privacy Policy and Cookies Policy, which are incorporated into these terms and conditions by this reference. To
						view the Privacy Policy and Cookies Policy, please click on the following: /privacy and /cookies.
					</li>
				</ol>

				<Header as='h3'>Availability of the Website and disclaimers</Header>
				<ol className="clauses">
					<li>Any online facilities, tools, services or information that
						{ owner } trading as { siteName }
						makes available through the Website (the <b>Service</b>) is provided "as is" and on an "as available" basis. We give no warranty that the Service will be free of defects and/or faults. To the
						maximum extent permitted by the law, we provide no warranties (express or implied) of fitness for a particular purpose, accuracy of information, compatibility and satisfactory quality.
						{ owner } trading as { siteName }
						is under no obligation to update information on the Website.
					</li>
					<li>
						Whilst { owner } trading as { siteName } uses reasonable endeavours
						to ensure that the Website is secure and free of errors, viruses
						and other malware, we give no warranty or guaranty in that regard
						and all Users take responsibility for their own security, that of
						their personal details and their computers.
					</li>
					<li>
						{ owner } trading as { siteName } accepts no liability for any
						disruption or non-availability of the Website.
					</li>
					<li>
						{ owner } trading as { siteName } reserves the right to alter,
						suspend or discontinue any part (or the whole of) the Website
						including, but not limited to, any products and/or services
						available. These terms and conditions shall continue to apply to
						any modified version of the Website unless it is expressly stated
						otherwise.
					</li>
				</ol>

				<Header as='h3'>Limitation of liability</Header>
				<ol className="clauses">

					<li>Nothing in these terms and conditions will: (a) limit or exclude our or your liability for death or personal injury resulting from our or your
						negligence, as applicable; (b) limit or exclude our or your liability for fraud or fraudulent misrepresentation; or (c) limit or exclude any of our or your
						liabilities in any way that is not permitted under applicable law.
					</li>
					<li> We will not be liable to you in respect of any losses arising out of events beyond our reasonable control.
					</li>

					<li>To the maximum extent permitted by law,
						{ owner } trading as { siteName } accepts no liability for any of the following:
						<ol>
							<li>
								any business losses, such as loss of profits, income, revenue, anticipated savings, business, contracts, goodwill or commercial opportunities;
							</li>
							<li>loss or corruption of any data, database or software;
							</li>
							<li>any special, indirect or consequential loss or damage.
							</li>
						</ol>
					</li>
				</ol>

				<Header as='h3'>General</Header>
				<ol className="clauses">
					<li>You may not transfer any of your rights under these terms and conditions to any other person. We may transfer our rights under these terms and
						conditions where we reasonably believe your rights will not be affected.
					</li>
					<li>These terms and conditions may be varied by us from time to time. Such revised terms will apply to the Website from the date of publication. Users
						should check the terms and conditions regularly to ensure familiarity with the then current version.
					</li>
					<li>These terms and conditions
						together with the Privacy Policy and Cookies Policy
						contain the whole agreement between
						the parties relating to its subject matter and supersede all prior discussions, arrangements or agreements that might have taken place in relation to the
						terms and conditions.
					</li>
					<li>The Contracts (Rights of Third Parties) Act 1999 shall not apply to these terms and conditions and no third party will have any right to enforce or
						rely on any provision of these terms and conditions.
					</li>
					<li>If any court or competent authority finds that any provision of these terms and conditions (or part of any provision) is invalid, illegal or
						unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other
						provisions of these terms and conditions will not be affected.
					</li>
					<li>
						Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right
						or remedy.
					</li>
					<li>This Agreement shall be governed by and interpreted according to the law of England and Wales and all disputes arising under the Agreement (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the English and Welsh courts.</li>
				</ol>

				<Header as='h3'>Processing and Dispatch</Header>
				<ol className="clauses">
					<li>All orders are usually processed and dispatched within 14 working days. <strong>This may change during busy periods</strong>, eg: Christmas, but I will keep you informed.</li>

					<li>If you need an product for a specific date? Please pop me an email before you order and I’ll try to accommodate on a best efforts basis.</li>
				</ol>

				<Header as='h3'>Delivery</Header>
				<ol className="clauses">
					<li>Delivery by our chosen provide (Royal Mail)</li>
					<li>We reserve the right to change delivery provider</li>
					<li>
						<p>
							All orders are dispatched Monday-Friday (excluding bank holidays) using either:
						</p>
						<p>
						</p>
							<strong>UK</strong>
							<ul>
								<li>1st Class Delivery</li>
								<li>2nd Class Signed For Delivery</li>
							</ul>
						<p>
							<strong>Outside UK</strong>
						</p>
							<ul>
								<li>Tracked delivery</li>
							</ul>
							<br />
					</li>
					<li>
						I can not be held responsible for any delay once an order is dispatched. But any problems, please get in touch and I will do my best to advise you.
					</li>
				</ol>

				<Header as='h3'>Cancellation and Returns</Header>
				<ol className="clauses">
					<li>All products are personalised so I don’t accept returns/exchanges.</li>
					<li>If you’d like to cancel an order, get in touch and if it hasn’t been started, no problem.</li>
					<li>
						Of course if there’s a mistake (it happens...) or an item doesn’t arrive in perfect condition,
						please contact me. I always try to make sure I have happy customers!
					</li>
					<li>
						Please return items to the following address only after you have contacted me first:
						<p style={ {padding: '10px'} }>
							VintageClayStudio<br />
							60 Croham Manor Road,<br />
							South Croydon.<br />
							CR2 7BF<br />
						</p>
					</li>
					<li>
            I will contact you as soon as I receive the return and keep you updated on progress.
					</li>
				</ol>

				<Header as='h3'>
					{ owner } trading as { siteName } details
				</Header>
				<ol className="clauses">
					<li>{ owner } trading as { siteName } of 60 Croham Manor Road, South Croydon, Surrey, CR2 7BF operates the Website  {siteUrl}.
						<p>
							You can contact { owner } trading as { siteName } by email on { email }.
						</p>
					</li>
				</ol>
			</Segment>
		}
}

export { TermsAndConditionsText }
