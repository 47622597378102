import React, { Component } from 'react';
import { Segment, Container, Header, Form, Button } from 'semantic-ui-react'
import { PageHeader, PageFooter } from '../components/layout.js';
import { siteConfig } from '../contexts/SiteContext';
import { SeoHeader } from '../components/seo'

class Contact extends Component {
	render () {
		return <Container>
			<SeoHeader seo={siteConfig.seo.contact } />
			<PageHeader />
			<Segment>
				<Header as='h1'>
					<Header.Content>
					  Contact Us
					</Header.Content>
				</Header>
			</Segment>
			<Segment>
				<Form>
					<Form.Field>
						<input placeholder='Your Name' />
					</Form.Field>
					<Form.Field>
						<input placeholder='Your email Address' />
					</Form.Field>
					<Form.Field>
						<input placeholder='Order Reference' />
					</Form.Field>
					<Form.Field>
						<input placeholder="What's it about" />
					</Form.Field>
					<Form.TextArea label='Message' placeholder='Tell us what you need...' />
					<Button primary type='submit'>Send</Button>
				</Form>
				</Segment>
			<PageFooter />
		</Container>
		}
}

export default Contact
