import React, { Component } from 'react';
import { Segment, Container, Header } from 'semantic-ui-react'
import { PageHeader, PageFooter } from '../components/layout.js';
import { TermsAndConditionsText } from '../components/terms_conditions_text.js';
import { siteConfig } from '../contexts/SiteContext';
import { SeoHeader } from '../components/seo'

class TermsConditions extends Component {
	render () {
		return <Container>
			<SeoHeader seo={ siteConfig.seo.tcs } />
			<PageHeader />
			<Segment>
				<Header as='h1'>
					<Header.Content>
					  Terms &amp; Conditions of use
					</Header.Content>
				</Header>
			</Segment>
		  <TermsAndConditionsText />
			<PageFooter />
		</Container>
		}
}

export default TermsConditions
