import React from 'react';
import { Container, Button, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CategoryService } from '../Service.js';
import { withRouter } from 'react-router'

class CategoryMenuInternal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { categories: [] }
		this.categoryService = new CategoryService()
		this.getCategories = this.getCategories.bind(this)
	}
	componentDidMount () {
		this.getCategories()
	}
	getCategories() {
		this.startLoading()
		this.categoryService.all()
			.then(categories => {
				this.setState({categories: categories, loading: false})
			})
	}
	startLoading() {
		this.setState({loading: true})
	}
	render() {
		let path = '/products/'
		let items = this.state.categories
		let { location } = this.props
		return <Menu.Menu position='left'>
				{Object.keys(items).map((itemKey) => {
					let category = items[itemKey]
					let uri = path + category.slug
					return <Menu.Item
						className={
							location.pathname === uri ? 'active' : ''
						} key={itemKey}>
						<Link to={uri} alt={category.description}>
							{category.name}
						</Link>
					</Menu.Item>
				})}
		</Menu.Menu>
	}
}

class CategoryList extends React.Component {
	constructor(props) {
		super(props);
		this.state = { categories: null }
    this.categoryService = new CategoryService()
		this.getCategories = this.getCategories.bind(this)
		this.onChange = this.onChange.bind(this);
	}
	componentDidMount () {
		this.getCategories()
	}
	getCategories() {
		this.startLoading()
		this.categoryService.all()
			.then(categories => {
				this.setState({categories: categories, loading: false})
			})
	}
	startLoading() {
		this.setState({loading: true})
	}
	onChange(item_id) {
		this.props.onChange(item_id);
	}
	render() {
		let items = this.state.categories
		let currentItem = this.props.currentItem
		return items && items.length
			? <Button.Group widths={items.length}>
								{Object.keys(items).map((key) => {
									return <Button active={currentItem && currentItem.id === items[key].id}
										fluid key={key}
										onClick={() => this.onChange(items[key].id)}>
										{items[key].name}
									</Button>
								})}
					</Button.Group>
		 : <Container textAlign='center'>No Categories found.</Container>
	}
}

const CategoryMenu = withRouter(CategoryMenuInternal)
export { CategoryList, CategoryMenu }
