import React from 'react';

class Money extends React.Component {
	render() {
		let value = this.props.item
		return <span>&pound;&nbsp;{parseFloat(value).toFixed(2)}</span>
	}
}

export default Money
