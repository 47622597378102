import React from 'react';
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { BasketService } from '../Service.js'

class BasketSummary extends React.Component {
	constructor() {
		super()
		this.state = { basket: null, loading: true, itemCount: 0}
    this.basketService = new BasketService()
		window.addEventListener('app-basket-change', (event) => {
      if (event.action === 'add') {
				this.setState({itemCount: this.state.itemCount + 1 })
			} else if (event.action === 'delete') {
				this.setState({itemCount: this.state.itemCount - 1 })
			}
	  })
	}
	componentDidMount () {
		this.getBasket()
	}
	getBasket() {
		this.basketService.all()
			.then(basket => this.setState({basket: basket, loading: false, itemCount: basket.order_items.length}))
	}

	render() {
		let { itemCount } = this.state

		return  <Link to={this.props.checkout_url} alt='Shopping Basket'>
			        <Icon name='shopping basket' />
						  ({itemCount}) item{ itemCount > 1 || itemCount === 0 ? 's' : '' }
					  </Link>
	}
}

export default BasketSummary
