
import React from 'react';
import { Card } from 'semantic-ui-react'
import { SiteContext } from '../contexts/SiteContext.js'

class OrderingInfo extends React.Component {
	render() {
		let product = this.props.item
		let hasMessage = product && (product.line_count > 0 || (product.children && product.children[0] && product.children[0].line_count > 0))

		return <Card raised fluid>
			<Card.Content>
				<Card.Header>How to Order ?</Card.Header>
				<Card.Description>
					<ol>
						<li>Click <b>Pick your Image</b> to upload your picture</li>
						<li>Adjust your pictures' size and position using the sliding bar</li>
						{ hasMessage && <li>Enter the text you want to include underneath the picture</li> }
						<li>If you're happy click <br/><b>Add to Basket</b></li>
					</ol>
				</Card.Description>
			</Card.Content>
		</Card>
	}
}

const ProcessingInfo = () => (
	<Card raised fluid>
	<Card.Content>
		<Card.Header>Processing Information</Card.Header>
		<Card.Description>
			<ul className="clauses">
				<li>
					All orders are usually processed and dispatched within 14 working days.
					<strong> This may change during busy periods</strong>, eg: Christmas,
						but I will keep you informed.
				</li>
				<li>
					If you need an product for a specific date? Please pop me an email
					before you order and I’ll try to accommodate on a best efforts basis.
				</li>
			</ul>
		</Card.Description>
	</Card.Content>
	</Card>
)

const DeliveryInfo = () => (
	<Card raised fluid>
		<Card.Content>
			<Card.Header>Delivery</Card.Header>
			<Card.Description>
				<ul className="clauses">
					<li>Delivery by our chosen provider (Royal Mail)</li>
					<li>We reserve the right to change delivery provider</li>
					<li>
						<p>
							All orders are dispatched Monday-Friday <i>(excluding bank holidays)</i> using either:
						</p>
							<strong>UK</strong>
							<ul>
								<li>1st Class Delivery</li>
								<li>2nd Class Signed For Delivery</li>
							</ul>
							<br />
							<strong>Outside UK</strong>
							<ul>
								<li>Tracked delivery</li>
							</ul>
							<br />
					</li>
					<li>
						I can not be held responsible for any delay once an order is
						dispatched. But any problems, please get in touch and I will do
						my best to advise you.
					</li>
				</ul>
			</Card.Description>
		</Card.Content>
	</Card>
)


class CancellationInfo  extends React.Component {
	render() {
		let address = this.context.address
	  return <Card raised fluid>
						<Card.Content>
							<Card.Header>Cancellation and Returns</Card.Header>
							<Card.Description>
									<ul className="clauses">
										<li>All products are personalised so I don’t accept returns/exchanges.</li>
										<li>If you’d like to cancel an order, get in touch and if it hasn’t been started, no problem.</li>
										<li>
											Of course if there’s a mistake (it happens...) or an item doesn’t arrive in perfect condition,
											please contact me. I always try to make sure I have happy customers!
										</li>
										<li>
											Please return items to the following address only after you have contact me first:
											<p style={ {padding: '10px'} }>
												{address}
											</p>
										</li>
										<li>
											I will contact you as soon as I receive the return and keep you updated on progress.
										</li>
									</ul>
							</Card.Description>
						</Card.Content>
					</Card>
	}
}

CancellationInfo.contextType = SiteContext

export { OrderingInfo, ProcessingInfo, DeliveryInfo, CancellationInfo }
