import React, { Component } from 'react'
import { Router , Route, Switch } from 'react-router-dom'
import Home from './views/Home'
import Products from './views/Products.js'
import ProductView from './views/Product.js'
import Checkout from './views/Checkout'
import NotFound from './views/NotFound'
import About from './views/About'
import TermsConditions from './views/TermsConditions'
import Privacy from './views/Privacy'
import Contact from './views/Contact'
import FAQ from './views/faq'
import Blog from './views/Blog'
import ArticleView from './views/Article'
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import './App.css';


const history = createBrowserHistory();

ReactGA.initialize(process.env.REACT_APP_GA);

history.listen(location => {
	ReactGA.set({ page: location.pathname }); // Update the user's current page
	ReactGA.pageview(location.pathname + location.search); // Record a pageview for the given page
});

class App extends Component {

  render () {
    return <Router history={history}>
      <Switch>
        <Route path='/' exact component={Home} />
				<Route path='/products/:categoryId' exact component={Products} />
				<Route path='/product/:productId' exact component={ProductView} />
				<Route path='/checkout' exact component={Checkout} />
				<Route path='/about' exact component={About} />
				<Route path='/tcs' exact component={TermsConditions} />
				<Route path='/privacy' exact component={Privacy} />
				<Route path='/contact' exact component={Contact} />
				<Route path='/faq' exact component={FAQ} />
				<Route path='/blog' exact component={Blog} />
				<Route path='/blog/:articleId' exact component={ArticleView} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  }
}

export default App
