import React, { Component } from 'react';
import { Menu, Segment, Grid, List, Container, Header, Icon, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { SiteContext } from '../contexts/SiteContext'
import { CategoryMenu } from './categories';
import BasketSummary from './basket_summary';
import logo from '../images/logo.jpg';

class MainHeaderInternal extends Component {
  render() {
		let checkoutUrl ='/checkout'
		let aboutSection = this.context.footer.sections.find((e)=> e.name === 'About')
		let menuItems = [
			aboutSection.menuItems.find((e)=> e.name === 'Info'),
			aboutSection.menuItems.find((e)=> e.name === 'FAQ'),
		]

		let siteName = this.context.title

    return <Container style={{ padding: '1em 0 0 0' }}>
				<Grid className="ui stackable equal height stackable grid">
          <Grid.Column width={12}>
            <Header as='h1' textAlign={ window.innerWidth < 720 ? 'center' : 'left' }>
              <Image src={logo} circular={  window.innerWidth < 720 ? true : false }  />
              <Header.Content style={{ fontFamily: 'bayberry', fontSize: '1.4em', paddingTop: '10px' }}>
                { siteName }
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <Menu text>
                {
                  Object.keys(menuItems).map((key) => {
                  let menuItem = menuItems[key]
                  return <Menu.Item key={key}>
                        <Link to={menuItem.uri}>
                          {menuItem.icon && <Icon name={menuItem.icon} />}
                          {menuItem.name}
                        </Link>
                      </Menu.Item>
                  })
                }
                <Menu.Item >
                  <BasketSummary checkout_url={checkoutUrl} />
                </Menu.Item>
              </Menu>
          </Grid.Column>
        </Grid>
    </Container>
  }
}
class PageHeaderInternal extends Component {
	render() {
		return <Container>
      <MainHeader />
			<PageMenu>
				<CategoryMenu />
			</PageMenu>
			{ this.props.children }
		</Container>
	}
}

class PageFooter extends Component {
	render() {
		let sections = this.context.footer.sections
		let email = this.context.contactEmail
		let siteName = this.context.businessEntity
		return <Segment vertical footer='true'>
			<Container>
				<Grid className="ui stackable divided equal height stackable grid">
					{Object.keys(sections).map((sectionKey) => {
						let section = sections[sectionKey]
						return <Grid.Column width={3} key={sectionKey}>
							<Header as='h4' color='green' >
								<Header.Content>
									{ section.name }
								</Header.Content>
							</Header>
							<List link>
								{Object.keys(section.menuItems).map((menutItemKey) => {
									let menuItem = section.menuItems[menutItemKey]
									return <List.Item key={menutItemKey}>
										<Link to={menuItem.uri}>{menuItem.icon && <Icon name={menuItem.icon} /> }{menuItem.name}</Link>
									</List.Item>
								})}
							</List>
						</Grid.Column>
					})}
					<Grid.Column width={10}>
						<Header as='h4' color='green'>
							<Header.Content>
								You can contact us by <Icon name='mail'/> @ &nbsp;<a href={'mailto:' + email}>{email}</a>
							</Header.Content>
						</Header>
						<p>
							Hello and Welcome! I'm the <i>designer, maker and owner</i> at Vintage Clay Studio.
						</p>
						<p>
							If you do not see anything you like please contact me for a bespoke gift. <strong style={{fontFamily: 'bayberry', fontSize: '1.5em'}}><i>Michelle x</i></strong>
						</p>
						<p>
							<a href='https://www.facebook.com/claytag/' title="Facebook"
								target="_blank" rel="nofollow noopener noreferrer">
								<i className="grey facebook icon"></i>Facebook
								</a>&nbsp;
							<a href="http://www.instagram.com/vintageclaystudio"
								title="Instagram"
								target="_blank" rel="nofollow noopener noreferrer">
								<i className="grey instagram icon"></i>Instagram
							</a>&nbsp;
							<a href="https://www.pinterest.co.uk/michelle3813/vintageclaystudio/#"
										title="Pinterest"
										target="_blank" rel="nofollow noopener noreferrer">
								<i className="grey link pinterest icon"></i>Pinterest
							</a>
							<a href="/"
								 title="copyright"
								 style={{float: 'right'}}
								 rel="nofollow noopener noreferrer">
								{siteName} &copy; { new Date().getFullYear() }
							</a>
						</p>
						<p ></p>
					</Grid.Column>
				</Grid>
			</Container>
		</Segment>
	}
}

class PageMenuInternal extends Component {
	render() {
		let menuItems = this.context.header.menuItems
		let { location } = this.props
		return <Menu stackable inverted color='green' size='small' >
			<Menu.Menu>
				{Object.keys(menuItems).map((menuItemKey) => {
					let menuItem = menuItems[menuItemKey]
					return <Menu.Item
						className={
							location.pathname === menuItem.uri ? 'active' : ''
						} key={menuItemKey}>
						<Link to={menuItem.uri}>
							{menuItem.icon && <Icon name={menuItem.icon} />}
							{menuItem.name}
						</Link>
					</Menu.Item>
				})}
			</Menu.Menu>
			{ this.props.children }
		</Menu>
	}
}

PageMenuInternal.contextType = SiteContext
PageFooter.contextType = SiteContext
PageHeaderInternal.contextType = SiteContext
MainHeaderInternal.contextType = SiteContext
const MainHeader = withRouter(MainHeaderInternal)
const PageHeader = withRouter(PageHeaderInternal)
const PageMenu = withRouter(PageMenuInternal)
export { PageHeader, PageMenu, PageFooter }
