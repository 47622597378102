class BaseService {
  async get(endpoint) {
		return fetch(this.buildUri(endpoint))
			.then(response => response.json())
			.catch(error => this.handleError(error))
	}
	async delete(endpoint) {
		return fetch(this.buildUri(endpoint),
			{ method: 'DELETE',
				headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.catch(error => this.handleError(error))
	}
	async post(endpoint, data) {
		return fetch(this.buildUri(endpoint),
			{ method: 'POST',
				headers: { 'Content-Type': 'application/json' },
			  body: JSON.stringify(data) })
			.then(response => response.json())
			.catch(error => this.handleError(error))
	}
	async put(endpoint, data) {
		return fetch(this.buildUri(endpoint),
			{ method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
			  body: JSON.stringify(data) })
			.then(response => response.json())
			.catch(error => this.handleError(error))
	}

	buildUri(path) {
		return '/api' + path;
	}

	handleResponseError(response) {
      throw new Error("HTTP error, status = " + response.status);
  }
  handleError(error) {
      console.log(error.message);
	}

	handlelist(items) {
		return (items && items.length) ? items : [];
	}
}

class BasketService extends BaseService {
	async all() {
		return this.get('/cart.json')
	}

	async add(item_data) {
		const payload = { order_item: item_data }
		return this.post('/order_items.json', payload)
			         .then(this.emitEvent('add', item_data))
	}

	async remove(item_id) {
		return this.delete('/order_items/' + item_id + '.json')
			.then(this.emitEvent('delete', { id: item_id, quantity: 0 }))
	}

	async update(item_id, quantity) {
		return this.put('/order_items/' + item_id + '.json', {quantity: quantity})
			.then(this.emitEvent('update', { id: item_id, quantity: quantity}))
	}

	async addOffer(code) {
		return this.post('/cart/add_offer.json', { code })
	}

	async removeOffer(code) {
		return this.post('/cart/remove_offer.json', { code })
	}

	emitEvent(action, item_data) {
		window.dispatchEvent(new Event('app-basket-change', { action: action, item_data: item_data }))
	}

}

class CategoryService extends BaseService {
	async all() {
		return this.get('/categories.json')
			  			 .then(list => { return this.handlelist(list) })
	}
}

class BlogService extends BaseService {
  async all() {
		return this.get('/blog.json')
			         .then(list => { return this.handlelist(list) })
	}
	async find(id) {
		return this.get(`/blog/${id}`)
	}
}

class ProductService extends BaseService {
  async all() {
		return this.get('/products.json')
			         .then(list => { return this.handlelist(list) })
	}
	async hot() {
		return this.get('/products.json?home_page=true')
			         .then(list => { return this.handlelist(list) })
	}
	async forCategory(id) {
		return this.get(`/products?category_id=${id}`)
							 .then(list => { return this.handlelist(list) })
	}
	async find(id) {
		return this.get(`/products/${id}`)
	}
}

class PaymentService extends BaseService {
	new() {
		return this.get('/payments/new.json')
	}

  create(order_id, processor_order_id, allow_sharing) {
		const payload = { payment: { order_id: order_id,
																 processor_order_id: processor_order_id,
																 allow_sharing: allow_sharing} }
		return this.post('/payments.json', payload)
  }
}

export { BasketService, ProductService, PaymentService, CategoryService, BlogService };
