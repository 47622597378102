import React from 'react';
import { Grid, Container, Input, Button, Message } from 'semantic-ui-react'

export class DiscountOffer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { code: props.code  }
		this.handleChange = this.handleChange.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
	}

	handleChange = (e)  => this.setState({ ...this.state, code: e.target.value || '' })
	handleAdd = () => this.props.onClick(this.state.code)
	handleRemove = () => {
		this.props.onClick('')
		this.setState({ ...this.state, code: '' })
	}

	render() {
		const code = this.state.code
		const error = this.props.errorMessage
		return <Container>
			<Grid columns='16'>
			<Grid.Column width={12} verticalAlign='top'>
				<Input fluid value={this.state.code} onChange={this.handleChange} />
				{ error &&
					<Message error attached='bottom'  textAlign='center' className='error'>
						<Message.Header>Sorry we can't apply that offer</Message.Header>
						<p>{error}</p>
					</Message>
				}
			</Grid.Column>
			<Grid.Column width={2} verticalAlign='top'>
				<Button.Group>
					<Button icon='plus' primary onClick={this.handleAdd} />
					{ (code && code.length > 0) && this.props.code &&
						<Button icon='minus' negative onClick={this.handleRemove} />
					}
					</Button.Group>
			</Grid.Column>
			</Grid>
			</Container>

	}
}
