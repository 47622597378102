import React from 'react';
import { Container, Header, Card, Image } from 'semantic-ui-react'
import Slider from 'infinite-react-carousel';
import Money from '../components/money.js';
import placeholder from '../images/img-placeholder.png'

class ProductGallery extends React.Component {
	render() {
		const settings = {
      arrowsBlock: false,
      dots: true,
      dotsScroll: 1,
			gutter: 50,
      shift: 10,
      slidesToShow: 3,
      wheel: true,
			wheelScroll: 2,
			className: 'productGallery'
		}
		const items = this.props.items
    const displayMeta = this.props.displayMeta
		return items && items.length > 0 && <Slider { ...settings } >
			{Object.keys(items).map((key) => {
				return <ProductListItem
          displayMeta={displayMeta}
					key={'product-list-item' + key}
					item={items[key]}
				  onClick={(item_id) => this.props.onClick(item_id.slug)} />
			})}
		</Slider>
	}
}

class ProductList extends React.Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}
	onClick(item_id) {
		this.props.onClick(item_id);
	}
	render() {
		const items = this.props.items
		const currentItem = this.props.currentItem
    const displayMeta = this.props.displayMeta || true
		return items && items.length
			? <Card.Group centered >
				{Object.keys(items).map((key) => {
					return <ProductListItem
                    displayMeta={displayMeta}
						        key={key}
						        isCurrentItem={currentItem && items[key] === currentItem.id}
									  item={items[key]}
										onClick={(item_id) => this.onClick(item_id.slug)} />
				})}
			</Card.Group>
			: <Container textAlign='center'>No items found.</Container>
	}
}

class ProductListItem extends React.Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}
	onClick(product) {
		this.props.onClick(product);
	}
	render() {
		const item = this.props.item
    const displayMeta = this.props.displayMeta
		return <Card raised onClick={() => this.onClick(item)}>
			{ item.image_main_url ?
						<Card.Content alt={item.name} style={{
							height:200,
							overflow:'hidden',
							margin:10,
							backgroundSize: 'cover',
							backgroundPosition: 'center center',
							backgroundImage: 'url(\'' +  item.image_main_url + '\')' }} >
						</Card.Content>
				: <Card.Content alt={item.name}><Image src={placeholder} /></Card.Content> }

      { displayMeta ?
          <Card.Content extra style={{height:180, overflow:'hidden'}}>
            <Header as='h4'>{item.name}</Header>
            <Card.Meta>
              <div>
              { item.description }
              </div>
            </Card.Meta>
          </Card.Content>
        : <Card.Content extra style={{height:70, overflow:'hidden'}}>
            <Header as='h4'>
             {item.name}
                   </Header>
          </Card.Content>
       }
       <Card.Content extra textAlign='right'>
         <Header as='h5'><Money item={item.price} /></Header>
       </Card.Content>
	  </Card>
  }
}
class ProductImages extends React.Component {
	render() {
   const settings = {
      arrowsBlock: false,
      dots: true,
      dotsScroll: 2,
      slidesToShow: 1,
      wheel: true,
			wheelScroll: 2
		}
		const thumbnails = this.props.image_urls
		return <Slider { ...settings } >
			{Object.keys(thumbnails).map((key) => {
				return <Image key={key} src={thumbnails[key]} fluid />
			})}
		</Slider>
	}
}
class Product extends React.Component {
	render() {
		const item = this.props.item
		return item
			? <Card fluid>
					<Card.Content>
				    <ProductImages image_urls={item.image_urls} />
					</Card.Content>
					<Card.Content>
						<Header as='h3'>{item.name}</Header>
						<Card.Description style={{textAlign: 'justify'}}>
							{item.description && <p>{item.description}</p>}
						</Card.Description>
					</Card.Content>
					<Card.Content>
						<Card.Meta>
							<strong>Weight:</strong> {item.shipping_weight && item.shipping_weight} (grams)
						</Card.Meta>
						<Card.Meta>
							<strong>Height:</strong>{ item.length } {item.unit_of_measurment}
						</Card.Meta>
						<Card.Meta>
							<strong>Width:</strong>  { item.width } {item.unit_of_measurment}
						</Card.Meta>
						{ item.children.length === 0 && <Card.Meta>
							<strong>Message lines:</strong> { item.line_count }
						</Card.Meta>}
					  { item.children.length === 0 &&<Card.Meta>
							<strong>Images:</strong> { item.image_count}
						</Card.Meta>}
					</Card.Content>
					<Card.Content>
						<Card.Meta textAlign='right'>
						  <Header as='h4'>Price: <Money item={item.price} /></Header>
						</Card.Meta>
					</Card.Content>
					<Card.Content extra>
						{this.props.children}
					</Card.Content>
				</Card>
			:  ''
	}
}
export { Product, ProductList, ProductGallery }
