import React, { Component } from 'react';
import { Segment, Container, Grid, Dimmer, Loader } from 'semantic-ui-react'
import { PageHeader, PageFooter } from '../components/layout.js';
import { Product } from '../components/products';
import BasketItem from '../components/basket_item';
import { BasketService, ProductService } from '../Service.js';
import { OrderingInfo } from '../components/help.js';
import { SeoHeader} from '../components/seo.js';

class ProductView extends Component {
	constructor(props) {
		super(props)
		this.state = { product: null, loading: true }

    this.productService = new ProductService()
    this.basketService = new BasketService()

		this.getProduct = this.getProduct.bind(this)
		this.addItemToBasket = this.addItemToBasket.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}
	componentDidMount () {
    const { match: { params } } = this.props;
		this.getProduct(params.productId)
	}
	startLoading() {
		this.setState({loading: true})
	}
	async addItemToBasket(item_data) {
		this.startLoading()
		const basket = await this.basketService.add(item_data)
		this.setState({basket: basket, showProductModal: false, loading: false})
		this.props.history.push('/Checkout')
	}
	getProduct(id) {
		this.startLoading()
		this.productService.find(id)
			.then(product => this.setState(
					{product: product,
					 showProductModal: true,
						loading: false}
			))
	}
  closeModal() {
	  this.setState({showProductModal: false})
	}
	render () {
		const product = this.state.product
		return <Container>
			{ product && <SeoHeader seo={product} /> }
		  { product && <PageHeader title={product.title} /> }
  		<Segment vertical>
				<Dimmer active={this.state.loading} inverted>
					<Loader content='Loading' size='large' />
				</Dimmer>
						<Dimmer active={this.state.loading} inverted>
							<Loader content='Adding to basket' size='large' />
						</Dimmer>
						<Grid stackable >
							<Grid.Column width={4}>
								<Product item={product} />
								<OrderingInfo item={product}/>
							</Grid.Column>
							<Grid.Column width={12}>
								{ product && <BasketItem
									busy={this.state.loading}
									product={product}
									addClick={(basket_item_data) => this.addItemToBasket(basket_item_data) }
									cancelClick={() => this.closeModal() }
								/> }
							</Grid.Column>
						</Grid>
			</Segment>
			<PageFooter />
		</Container>
	}
}

export default ProductView
