import React, { Component } from 'react';
import { Segment, Container, Dimmer, Loader } from 'semantic-ui-react'
import { PageHeader, PageFooter } from '../components/layout.js';
import { Article } from '../components/articles';
import { SeoHeader} from '../components/seo.js';
import { BlogService } from '../Service';

class ArticleView extends Component {
	constructor(props) {
		super(props)
		this.state = { article: null, loading: true }

    this.blogService = new BlogService()
		this.getArticle = this.getArticle.bind(this)
	}
	componentDidMount () {
    const { match: { params } } = this.props;
		this.getArticle(params.articleId)
	}
	startLoading() {
		this.setState({loading: true})
	}
	getArticle(id) {
		this.startLoading()
		this.blogService.find(id)
			.then(article => this.setState(
					{article: article,
					 showArticleModal: true,
						loading: false}
			))
	}
	render () {
		const article = this.state.article
		return <Container>
			{ article && <SeoHeader seo={article} /> }
		  { article && <PageHeader title={article.name} /> }
  		<Segment vertical>
				<Dimmer active={this.state.loading} inverted>
					<Loader content='Loading' size='large' />
				</Dimmer>
			  <Article item={article} />
			 </Segment>
			<PageFooter />
		</Container>
	}
}

export default ArticleView
