import React, { Component } from 'react';
import { Segment, Container, Header, Card } from 'semantic-ui-react'
import { PageHeader, PageFooter } from '../components/layout.js';
import { ProcessingInfo, DeliveryInfo, CancellationInfo } from '../components/help.js';
import { FAQContext } from '../contexts/FAQContext.js';
import { siteConfig } from '../contexts/SiteContext';
import { SeoHeader } from '../components/seo'

class FAQ extends Component {
	render () {
		let questions = this.context.questions
		return <Container>
			<SeoHeader seo={siteConfig.seo.faq} />
			<PageHeader />
			<Segment>
				<Header as='h1'>
					<Header.Content>
					  Frequently Asked Questions
					</Header.Content>
				</Header>
			</Segment>
			{
				Object.keys(questions).map((key) => {
				  let item = questions[key]
					return <Card key={key} header={item.q} description={item.a} fluid />
			   })
			}

			<Segment>
				<Header as='h2'>Other useful information</Header>
			<ProcessingInfo />
		  <DeliveryInfo />
		  <CancellationInfo />
			<PageFooter />
		</Segment>
		</Container>
		}
}

FAQ.contextType = FAQContext
export default FAQ
